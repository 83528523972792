<template>
  <v-row
    class="align-right"
    justify="center"
  >
    <v-col
      class="pa-4"
      cols="12"
      lg="4"
      md="6"
      sm="8"
      xs="6"
    >
      <div>
        <h2>Ustaw nowe hasło</h2>
        <p class="mb-6">Proszę wprowadzić dwukrotnie nowe hasło.</p>
        <p class="password-requirements">Minimalna długość hasła wynosi 8 znaków. Hasło powinno zawierać przynajmniej 1 dużą literę i 1 cyfrę</p>
      </div>
      <v-form
        v-model="isFormValid"
        @submit.prevent="resetPassword"
      >
        <v-text-field
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :autofocus="true"
          :rules="passwordRules"
          :type="show1 ? 'text' : 'password'"
          counter
          label="Nowe hasło"
          required
          @click:append="show1 = !show1"
        />
        <v-text-field
          v-model="confirmPassword"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[...passwordRules, confirmPasswordRule]"
          :type="show2 ? 'text' : 'password'"
          class="mt-2"
          label="Potwierdź hasło"
          required
          @click:append="show2 = !show2"
        />
        <v-row
          class="align-right"
          justify="center"
        >
          <v-col cols="6"></v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <v-btn
              :to="{name: 'Login'}"
              link
              small
              text
            >
              Wróć do logowania
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          :loading="loading"
          block
          class="mt-4"
          color="primary"
          large
          type="submit"
        >
          Ustaw hasło
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {password, required} from "@/utils/validationRules";
import {resetPassword} from "@/services/authService";
import {notify, notifyStandardError} from "@/services/notificationService";

export default {
  name: 'ResetPassword',
  data: () => ({
    password: '',
    passwordRules: [required, password,],

    confirmPassword: '',
    isFormValid: false,
    emailSent: false,
    loading: false,
    show1: false,
    show2: false,
  }),
  computed: {
    confirmPasswordRule() {
      return this.password === this.confirmPassword || 'Hasła nie pasują'
    },
  },
  methods: {
    async resetPassword() {
      try {
        if (this.isFormValid) {
          this.loading = true
          const token = this.$route.params.token
          await resetPassword(this.password, token)
          notify('success', 'Hasło zostało zmienione', 'Możesz zalogować się')
          await this.$router.push({name: 'Login'})
        }
      } catch (e) {
        notifyStandardError()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .password-requirements {
    font-size: 0.85em;
  }
</style>
